<template>
    <div class="wrapper_main" :class="{ 'processing':loading }" style="min-height: 100dvh;">
        <main class="content" v-if="!loading">
            <div class="content__header">
                <div class="content__header-left">
                    <h1 class="heading-page-h1">{{ $translate('livestock-list') }}</h1>
                </div>
                <div class="content__header-right">
                    <BluePlusButton @click="addZone('herd')">{{ $translate('add-herd') }}</BluePlusButton>
                </div>
            </div>

            <TableAdvanced v-model="sortCol" :rows="filteredRows" :columns="columns" :isCheckedAll="isCheckedAll" :checkedInputs="checkedInputs" @toggleAllCheckedInputs="toggleAllCheckedInputs">
				<template v-slot:filters>
                    <div class="filter-options__search">
                        <input type="search" class="input bg-left search-icon" placeholder="Search" v-model="search" @input="initTables" />
                    </div>
                    <div class="filter-options__items flex-x-wrap flex-auto">
                        <div>
                            <label class="el-form__title select-title" style="display: block;">{{ $translate('date-creation-(from)') }}</label>
                            <DatePicker v-model="filterStartDate" dateFormat="MM.dd.yyyy" :canClear="true" />
                        </div>
                        <div>
                            <label class="el-form__title select-title" style="display: block;">{{ $translate('date-creation-(to)') }}</label>
                            <DatePicker v-model="filterEndDate" dateFormat="MM.dd.yyyy" :canClear="true" />
                        </div>
                        
                        <!-- <div class="categories time-piker dropdown-wrapper">
                            <TimePicker v-model="filterTime" initValue="00:00" :canClear="true" />
                        </div> -->
                    </div>
                    <div class="flex items-center">
                        <span class="select-title">{{ $translate('sort-by') }}</span>
                        <!-- :options="['Default', 'Date', 'Name',]" -->
                        <Dropdown
                            :options="['Date of creation', 'Name',]"
                            :current="sortField"
                            name="sortFeild"
                            @change="updateSortField"
                        />
                        <button type="button" class="btn btn-sm btn-w-shadow size-40-40" data-target="download" @click="openDownloadList">
                            <inline-svg :src="require(`@/assets/img/ico-download.svg`)"/>
                        </button>
                    </div>
				</template>

				<template v-slot:row="slotData">
					<td>
						<label class="module__check">
                            <input type="checkbox" name="category" :checked="checkedInputs[slotData.r.id]" @change="setCheckedInputs($event,slotData.r.id)"/>
							<span class="check"></span>
							<span class="text" @click.self.stop.prevent="edit(slotData.r)">{{ slotData.r.basic_info.name }}</span>
						</label>
					</td>
                    <td>
                        {{ 
                            slotData.r.basic_info.type === "Custom animal" || slotData.r.basic_info.type === "Other" && slotData.r.basic_info.custom_type ?
                            slotData.r.basic_info.custom_type : slotData.r.basic_info.type || '-' 
                        }}
                    </td>
                    <td>{{ slotData.r.basic_info.breed || '-' }}</td>
                    <td>{{ slotData.r.basic_info.status || '-' }}</td>
                    <td>{{ slotData.r.child_paddock && paddockList.find(itemP => itemP.id === slotData.r.child_paddock).basic_info.name || '-' }}</td>
					<td>{{ getFirstDeviceName(slotData.r.id) ? getFirstDeviceName(slotData.r.id).name : '-' }}</td>
                    <td class="position-stiky">
                        <div class="table-options-drop dropdown-wrapper">
                            <button
                                type="button" class="btn btn-sm btn-transp dropdown-btn" 
                                :class="{'active':menuOpen.open === slotData.r.id }"
                                @click="openOptions($event, slotData.r.id)"
                            >
                                <inline-svg :src="require('@/assets/img/dots-menu.svg')" class="pointer-events-none" />
                            </button>
                            <div 
                                class="categories__dropdown dropdown-content" 
                                :class="{'active':menuOpen.open === slotData.r.id }"
                                style="position: fixed;"
                                :style="{ left: menuOpen.left, top: menuOpen.top, bottom: 'auto' }"
                            >
                                <ul>
									<li>
										<a @click="edit(slotData.r)">{{ $translate('edit') }}</a>
									</li>
									<li>
										<a>{{ $translate('report') }}</a>
									</li>
									<li class="list-line"></li>
									<li>
										<button type="button" class="red-color" @click="openConfirmDelete(slotData.r)">
											{{ $translate('delete') }}
										</button>
									</li>
                                </ul>
                            </div>
                        </div>
                    </td>
				</template>	
			</TableAdvanced>
            <div class="empty_table_data_info" v-if="!sourceData.length">
                <p>{{ $translate('data-not-found.-Reset-filters-or-add-a-new-element.') }}</p>
            </div>
            <Pagination v-model="filteredRows" :items="rawSource" />
        </main>

        <FarmListDownload
            v-if="downloadList"
            :tableName="$translate('livestock-list')"
            @close="closeDownloadList"
            @confirm="confirmDownloadList"
        />
        <!-- before translate tableName="Livestock List" -->

        <ConfirmAction
			v-if="deletePopupInfo.show" 
			:title="$translate('delete-herd?')" 
			:message="$translate('do-you-confirm-delete-a-herd') + ' ' + deletePopupInfo.name + '?'" 
			@close="confirmDeleteClose" 
			@confirm="deletePaddock(deletePopupInfo.deleteId)" 
			:processing="loading"
		/>
        <!-- before translate 
            title="Delete Herd?" 
			:message="'Do you confirm delete a herd '+ deletePopupInfo.name + '?'" 
         -->
    </div>
</template>

<script>
import Base from "@/components/base";
import Popups from "@/components/popups";
import Buttons from "@/components/buttons"
import { API, Storage } from 'aws-amplify';
import { mixTables,mixDeleteItem } from '@/mixins';

import {
	deletePaddock,
	updatePaddock,
    updateDevice,
} from '@/graphql/mutations';

export default {
    name: "LivestockList",
    props: ['forcePaddockList'],
    components: {
        ...Base,
        ...Popups,
        ...Buttons
    },
    mixins: [mixTables,mixDeleteItem],
    data() {
        return {
            search: '',
            sortField: "Date of creation",
            filterStartDate: null,
            filterEndDate: null,
            filterTime: '00:00',
            downloadList: false,
            loading: true,
            source: 'paddocks',
			columns: [
				{ name: 'Name', filter: 'name' },
                { name: 'Type', filter: 'basic_info.type' },
                { name: 'Breed', filter: 'basic_info.breed' },
                { name: 'Status', filter: 'basic_info.status' },
                { name: 'Related Fields/Paddock', filter: 'child_paddock' },
				{ name: 'IOT Device', filter: '', colspan: 2 },
			],
            calculatedFields:[{
				filter: 'name',
				path: ['basic_info'],
				calcFunction: (basic_info) => basic_info.name
			},
            {
				filter: 'basic_info.type',
				path: ['basic_info'],
				calcFunction: (basic_info) => basic_info.type
			},
            {
				filter: 'basic_info.status',
				path: ['basic_info'],
				calcFunction: (basic_info) => basic_info.status
			},
            {
				filter: 'date_of_birth',
				path: ['basic_info'],
				calcFunction: (basic_info) => basic_info.date_of_birth
			},], 
            deletePopupInfo: {
                show: false,
                name: '',
                deleteId: null,
            },
            paddocksLocale: [],
            menuOpen: {
                left: '-500px',
                top: 0,
                open: null,
            }
        };
    },
    computed: {
        paddockList(){
            return this.forcePaddockList ? this.forcePaddockList : this.$store.state.paddocks;
        },
        devices(){
            return this.$store.state.devices;
        },
        sourceData(){
            return this.paddocksLocale.filter(item => item.type === "herd" && item.basic_info.name.toLowerCase().includes(this.search.toLowerCase()));
        }
    },
    watch: {
        filterStartDate(){
            this.filtersDateChange();
        },
        filterEndDate(){
            this.filtersDateChange();
        },
        filterTime(){
            this.filtersDateChange();
        },
    },
    methods: {
        openOptions(ev, id) {
            if(this.menuOpen.open === id){
                this.menuOpen.open = null;
                this.menuOpen = {
                    left: '-500px',
                    top: 0,
                    open: null,
                }
            } else {
                this.menuOpen.open = id;
                setTimeout(() => {
                    let targetClientRect = ev.target.getBoundingClientRect();

                    this.menuOpen.left = (targetClientRect.x - ev.target.nextElementSibling.offsetWidth + 22) + 'px';

                    if((targetClientRect.top + targetClientRect.height + ev.target.nextElementSibling.offsetHeight+70) > document.querySelector('#app').offsetHeight){
                        this.menuOpen.top = ((targetClientRect.y - ev.target.nextElementSibling.offsetHeight) - 10) + 'px';
                    } else {
                        this.menuOpen.top = (targetClientRect.y + 15) + 'px';
                    }
                }, 10);
            }
        },
        outsideMenuClick(e){
            document.querySelectorAll('.table-options-drop.dropdown-wrapper').forEach(element => {
                if (this.menuOpen.open && !e.target.closest('.table-options-drop.dropdown-wrapper')){
                    this.menuOpen.open = null;
                }
            });
        },
        getFirstDeviceName(itemId){
            return this.devices.find(item => item.paddockID === itemId);
        },
        updateSortField(ev, forceupdate = false) {
            let target = null;
            let value = null;
            if(!forceupdate){
                target = ev.target;
                value = target.value; 
            } else {
                target = null;
                value = ev;
            }

            this.sortField = value;
            
            if(value === 'Default' && !forceupdate){
                this.paddocksLocale = [...this.paddockList];
                return;
            }

            this.paddocksLocale.sort(function(a, b){
                if(value === 'Name'){
                    if(a.basic_info.name.toLowerCase() < b.basic_info.name.toLowerCase()) { return -1; }
                    if(a.basic_info.name.toLowerCase() > b.basic_info.name.toLowerCase()) { return 1; }
                }

                if(value === 'Date of creation'){
                    if(a.createdAt < b.createdAt) { return -1; }
                    if(a.createdAt > b.createdAt) { return 1; }
                }

                return 0;
            });
            this.initTables();
        },
        filtersDateChange(){
            if(this.filterStartDate && this.filterEndDate){
                this.paddocksLocale = [...this.paddockList].filter(item => new Date(item.createdAt) >= new Date(`${this.filterStartDate}, ${this.filterTime}`) 
                    && new Date(item.createdAt) <= new Date(`${this.filterEndDate}, ${this.filterTime}`));
            }

            if(this.filterStartDate && !this.filterEndDate) {
                this.paddocksLocale = [...this.paddockList].filter(item => new Date(item.createdAt) >= new Date(`${this.filterStartDate}, ${this.filterTime}`));
            }

            if(this.filterEndDate && !this.filterStartDate) {
                this.paddocksLocale = [...this.paddockList].filter(item => new Date(item.createdAt) <= new Date(`${this.filterEndDate}, ${this.filterTime}`));
            }

            if(!this.filterStartDate && !this.filterEndDate) {
                this.paddocksLocale = [...this.paddockList];
            }

            this.updateSortField(this.sortField, true);
            this.initTables();
        },
        pushRoute(page) {
            this.$router.push({ path: page });
        },
        openDownloadList() {
            this.downloadList = true;
        },
        closeDownloadList() {
            this.downloadList = false;
        },
        confirmDownloadList(format) {
            console.log(format);
            this.closeDownloadList();
        },
        openConfirmDelete(paddock){
            this.deletePopupInfo.deleteId = paddock.id;
            this.deletePopupInfo.name = paddock.basic_info.name;
            setTimeout(() => {
                this.deletePopupInfo.show = true;
            }, 50);
            this.menuOpen.open = null;
        },
        confirmDeleteClose(){
            this.deletePopupInfo = {
                show: false,
                name: '',
                deleteId: null,
            };
        },
        async deletePaddock(paddockId){
            this.loading = true;
            let findedDevice = this.devices.find(item => item.paddockID === paddockId);
            if(findedDevice) {
                await API.graphql({
                    query: updateDevice,
                    variables: {
                        input: {
                            id: findedDevice.id,
                            paddockID: null
                        }
                    }
                });
            }

            let findedInChild = this.paddockList.find(item => item.child_paddock === paddockId);
            if(findedInChild){
                await API.graphql({
                    query: updatePaddock,
                    variables: {
                        input: {
                            id: findedInChild.id,
                            child_paddock: null
                        }
                    }
                });
            }

            await API.graphql({
                query: deletePaddock,
                variables: {
                    input: {
                        id: paddockId,
                    }
                }
            });

            this.confirmDeleteClose();
            await this.$store.dispatch('GET_DEVICES');
            await this.$store.dispatch('GET_PADDOCKS');
            this.paddocksLocale = [...this.paddockList];
            this.updateSortField(this.sortField, true);

            this.initTables();
            setTimeout(() => {
                this.loading = false;
            }, 1000);
        },
        edit(paddock){
            this.$router.push({ path: '/farm-map', query: { 'edit': paddock.id } });
        },
        addZone(type){
            this.$router.push({ path: '/farm-map', query: { 'add': type } });
        }
    },
    async created(){
        let farm = null;
        farm = await this.$store.dispatch('GET_FARM');
        if(farm){
            await this.$store.dispatch('GET_PADDOCKS');
            this.paddocksLocale = [...this.paddockList];
            this.initTables();
        }      
        this.loading = false;

        let self = this;
        window.addEventListener('click', self.outsideMenuClick);
    },
    beforeDestroy(){
        let self = this;
        window.removeEventListener('click', self.outsideMenuClick);
    }
};
</script>
