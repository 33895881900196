export const operators = {
    'equal': 'Equal',
    'not_equal': 'Not Equal',
    'greater_than': 'Greater Than',
    'less_than': 'Less Than',
    'equal_or_grater_than': 'Equal or Grater Than',
    'equal_or_less_then': 'Equal or Less Than',
}

// export const operators = {
//     'one-off':'One-off',
//     'periodic':'Periodic'
// }

export const alert_actions = {
    'sms_email': [],
}