<template>
    <div class="farm-map-page map search-map" style='border-radius: var(--border-radius-7); overflow: hidden; margin-top: 10px;'>
        <div id="map" :style="{ width: '100%', height: mapHeight }" style="min-height: 300px;"></div>
        <div class="zoom-control" v-show="!print">
            <a class="zoom-control-in">
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_3220_32971)">
                        <g filter="url(#filter0_d_3220_32971)">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.75 3.5C12.75 3.08579 12.4142 2.75 12 2.75C11.5858 2.75 11.25 3.08579 11.25 3.5V11.75H3C2.58579 11.75 2.25 12.0858 2.25 12.5C2.25 12.9142 2.58579 13.25 3 13.25H11.25V21.5C11.25 21.9142 11.5858 22.25 12 22.25C12.4142 22.25 12.75 21.9142 12.75 21.5V13.25H21C21.4142 13.25 21.75 12.9142 21.75 12.5C21.75 12.0858 21.4142 11.75 21 11.75H12.75V3.5Z" fill="white"/>
                        </g>
                    </g>
                    <defs>
                        <filter id="filter0_d_3220_32971" x="0.25" y="0.75" width="23.5" height="23.5" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset/>
                            <feGaussianBlur stdDeviation="1"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3220_32971"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3220_32971" result="shape"/>
                        </filter>
                        <clipPath id="clip0_3220_32971">
                            <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
                        </clipPath>
                    </defs>
                </svg>
            </a>
            <a class="zoom-control-out">
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_3220_32976)">
                        <path d="M3 12.5H21" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                        <filter id="filter0_d_3220_32976" x="0.25" y="9.75" width="23.5" height="5.5" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset/>
                            <feGaussianBlur stdDeviation="1"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3220_32976"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3220_32976" result="shape"/>
                        </filter>
                    </defs>
                </svg>
            </a>
        </div>
        <a class="maptype-control" v-show="!print">
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_3220_32980)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.89863 16.5086L10.0391 10.8242C10.1186 10.4282 10.4283 10.1189 10.8244 10.0399L16.5088 8.90343C16.6727 8.87081 16.842 8.92216 16.9602 9.04031C17.0783 9.15846 17.1297 9.32781 17.097 9.49168L15.9606 15.176C15.8813 15.5718 15.572 15.8811 15.1762 15.9604L9.49187 17.1018C9.3262 17.137 9.15397 17.086 9.03421 16.9663C8.91445 16.8465 8.86343 16.6743 8.89863 16.5086Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" shape-rendering="crispEdges"/>
                </g>
                <g filter="url(#filter1_d_3220_32980)">
                    <circle cx="13.0003" cy="13.0001" r="9.00375" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" shape-rendering="crispEdges"/>
                </g>
                <defs>
                    <filter id="filter0_d_3220_32980" x="6.1377" y="6.1438" width="13.7188" height="13.719" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset/>
                        <feGaussianBlur stdDeviation="1"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3220_32980"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3220_32980" result="shape"/>
                    </filter>
                    <filter id="filter1_d_3220_32980" x="1.24658" y="1.24634" width="23.5073" height="23.5076" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset/>
                        <feGaussianBlur stdDeviation="1"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3220_32980"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3220_32980" result="shape"/>
                    </filter>
                </defs>
            </svg>
        </a>
        <div class="search_input_wrpr" v-show="!print">
            <input 
                type="text" placeholder="Search" class="input bg-left search-icon" 
                v-model="searchAdress" @input="geocodeForward" :class="{ 'bb_0':searchAdress.length }"
            >
            <ul v-show="searchAdress.length">
                <template v-if="searchResult.length">
                    <li v-for="(item, index) in searchResult" :key="index">
                        <a @click="setPosFromSearch({ lat: item.latitude, lng: item.longitude })">{{ item.label }}</a>
                    </li>
                </template>
                <li v-else>
                    <a>{{ $translate('address-not-found') }}</a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Base from "@/components/base";
import basicInfoConfig from '@/config/farm';

export default {
    components: {
        ...Base,
    },
    props: ['paddocks', 'print', 'mapHeightProps'],
    data(){
        return {
            basicInfoConfig: basicInfoConfig.basicInfoConfig,
            loading: true,
            filtersShow: false,
            filtersArray: ['Herd Zones', 'Building Zones', 'Infrastructure Zones', 'Field Zones', 'Paddock Zones',],
            filtersArrayActive: [],
            searchAdress: '',
            searchResult: [],
            fromInit: null,
            addedObjectType: null,
            addObjectState: false,
            tabs: ['Map', 'Basic Info', 'Assign Devices'],
            mapHeight: this.mapHeightProps ? this.mapHeightProps : 'calc(100vh - 210px)',
            basicInfo: {},
            basicInfoVarietyCount: 1,
            drawType: null,
            addedFigure: null,
            updateKeyNumber: 0,
            addedDevices: [],
            editPaddock: null,
            childPaddock: null,
            initialZoneData: null,
        }
    },
    computed: {
        devices(){
            return this.$store.state.devices;
        },
        // paddocks(){
        //     return this.$store.state.paddocks;
        // },
        computedFilters(){
            let filtersObject = {
                'Herd Zones': 'herd',
                'Building Zones': 'building',
                'Infrastructure Zones': 'infrastructure',
                'Field Zones': 'field',
                'Paddock Zones': 'paddocks',
            };
            return Object.entries(filtersObject).filter(item => this.filtersArrayActive.includes(item[0])).map(item => item[1]);
        }
    },
    watch:{
        async computedFilters(){
            this.loading = true;
            await this.initFarm();
            this.loading = false;
        }
    },
    methods: {
        initMap() {
			let payload = {
                lat: 45.6219172254747,
                lng: 9.284690169503811,
                type_filters: this.computedFilters,
                dontClickPaddocks: true,
            };

			this.fromInit = this.$provider.gmap.createMap('map', payload, (res) => {
				const event = {
					target: {
						name: 'lat_lng',
						value: res
					}
				}
			}, true, [...this.paddocks].filter(item => 
                (item.type === 'building' && this.computedFilters.includes(item.basic_info.type.toLowerCase())) || 
                (item.type !== 'building' && this.computedFilters.includes(item.type))
            ));

            this.$provider.gmap.on('addDrawing', data => {
                this.setAddedFigureData(data);

                this.fromInit.drawingManager.setDrawingMode(null);
            })
            
            this.$provider.gmap.on('editDrawing', data => {
                this.setAddedFigureData(data);
            })
            
            this.$provider.gmap.on('editMapDrawingObjects', data => {
                if(!this.$route.query.edit || this.$route.query.edit !== data.id) {
                    this.$router.push({ query: { 'edit': data.id } });
                }

                this.mapHeight = this.mapHeightProps ? this.mapHeightProps : 'calc(100vh - 333px)';

                this.addedDevices = [];

                this.editPaddock = data;
                this.basicInfo = data.basic_info;
                this.addedFigure = data.map_info;
                this.addedObjectType = data.type ? data.type : 'paddocks';
                if(this.addedObjectType === 'herd') {
                    this.tabs = ['Map', 'Basic Info', 'Assign Devices', 'Assign Paddock / Building'];
                    this.childPaddock = data.child_paddock;
                } else {
                    this.tabs = ['Map', 'Basic Info', 'Assign Devices'];
                }

                if(this.basicInfo.number_of_plots) this.numberOfPlotsTo('number');
                
                this.devices.forEach(element => {
                    if(element.paddockID === this.editPaddock.id && !this.addedDevices.includes(element.id)) {
                        this.addedDevices.push(element.id);
                    }
                });

                this.addObjectState = true;
                this.initialZoneData = JSON.parse(JSON.stringify(data));
            })
		},
        setAddedFigureData(data) {
            if(data.type === "polygon") {
                if('data_emited' in data){
                    this.addedFigure = {
                        type: data.type,
                        points: data.data_emited.points,
                    }
                    if('total_area' in  this.basicInfo) this.basicInfo.total_area = data.data_emited.total_area;
                } else {
                    if('total_area' in  this.basicInfo) this.basicInfo.total_area = this.fromInit.google.maps.geometry.spherical.computeArea(data.getPath()).toFixed(2) +' m2';

                    this.addedFigure = {
                        type: data.type,
                        points: [],
                    }

                    for (let index = 0; index < data.getPath().getArray().length; index++) {
                        this.addedFigure.points.push({ 
                            lat: data.getPath().getArray()[index].lat(), 
                            lng: data.getPath().getArray()[index].lng() 
                        });
                    }
                }
            }

            if(data.type === "circle") {
                if('total_area' in  this.basicInfo) this.basicInfo.total_area = (Math.PI * Math.pow(data.radius, 2)).toFixed(2) + ' m2';

                this.addedFigure = {
                    center_lat: data.center.lat(),
                    center_lng: data.center.lng(),
                    type: data.type,
                    radius: data.radius,
                }
            }

            if(data.type === "marker") {
                this.addedFigure = {
                    center_lat: data.position.lat(),
                    center_lng: data.position.lng(),
                    type: data.type,
                }
            }
        },
        async geocodeForward() {
            this.searchResult = [];
			const { data } = await this.$provider.positionStack.getForward(this.searchAdress);
			if(data.length) {
				const { 
					latitude, 
					longitude,
				} = data[0];
                this.searchResult = data;
			}
		},
        setPosFromSearch(cords){
            this.$provider.gmap.setCords('map', cords, true);
            this.searchResult = [];
            this.searchAdress = '';
        },
        addObjectInMap(type){
            if(type === 'herd') {
                this.tabs = ['Map', 'Basic Info', 'Assign Devices', 'Assign Paddock / Building'];
            } else {
                this.tabs = ['Map', 'Basic Info', 'Assign Devices'];
            }

            this.basicInfo = {};
            for (let index = 0; index < this.basicInfoConfig[type].length; index++) {
                this.basicInfo[this.basicInfoConfig[type][index].id] = '';
                if(this.basicInfoConfig[type][index].id === 'variety'){
                    this.basicInfo[this.basicInfoConfig[type][index].id] = [''];
                }
                if(this.basicInfoConfig[type][index].id === 'crop'){
                    this.basicInfo[this.basicInfoConfig[type][index].id] = [];
                }
                if(this.basicInfoConfig[type][index].id === 'plots_config'){
                    this.basicInfo[this.basicInfoConfig[type][index].id] = [];
                }
            }

            this.addedObjectType = type;

            this.addObjectState = true;
            this.mapHeight = this.mapHeightProps ? this.mapHeightProps : 'calc(100vh - 333px)';

            if(type === 'herd') {
                this.fromInit.drawingManager.setDrawingMode(this.fromInit.google.maps.drawing.OverlayType.MARKER);
                this.drawType = 'MARKER';
            } else {
                this.fromInit.drawingManager.setDrawingMode(this.drawType ? 
                    this.fromInit.google.maps.drawing.OverlayType[this.drawType] : 
                    this.fromInit.google.maps.drawing.OverlayType.POLYGON
                );
                this.drawType = 'POLYGON';
            }
        },
        async initFarm(){
            if(this.$store.state.companyData && this.$store.state.companyData.id){
                let farm = await this.$store.dispatch('GET_FARM');
                if(!farm){
                    await this.$store.dispatch('GET_FARM');
                }
                await this.$store.dispatch('GET_PADDOCKS');
                await this.$store.dispatch('GET_DEVICES');

                setTimeout(async () => {
                    await this.initMap();
                }, 1000);
            } else {
                setTimeout(() => {
                    this.initFarm();
                }, 1000);
            }


            if(this.$route.query.edit){
                this.$provider.gmap.editObject = this.$route.query.edit;
            }

            if(this.$route.query.add){
                setTimeout(() => {
                    this.addObjectInMap(this.$route.query.add);
                }, 1000);
            }
        }
    },
    created(){
        this.tabs = ['Map', 'Basic Info', 'Assign Devices'];
        this.filtersArrayActive = [...this.filtersArray];
    },
    async mounted(){
        if(this.print) {
            this.mapHeight = this.mapHeightProps ? this.mapHeightProps : '50vh';
        }
    
        await this.initFarm();
        this.loading = false;
    }
}
</script>