<template>
    <div>
        <div class="filter-options" v-if="false">
            <div class="filter-options__items flex-xxl-wrap flex-auto">
                <DatePicker></DatePicker>
                <DatePicker></DatePicker>
                <div class="categories time-piker dropdown-wrapper">
                    <div id="datetimePickerc4fc20a0-75fe-4a8f-94a5-13e4aa517ff7">
                        <div class="datetimepicker-dummy is-primary">
                            <div class="datetimepicker-dummy-wrapper">
                                <input placeholder="" readonly="readonly" class="datetimepicker-dummy-input" type="text" />

                                <input type="text" class="time is-hidden" value="00:00" />
                            </div>
                            <button class="datetimepicker-clear-button">＋</button>
                        </div>
                        <div class="datetimepicker-wrapper">
                            <div class="modal-background is-hidden"></div>
                            <div class="datetimepicker is-primary is-datetimepicker-default" style="position: absolute">
                                <div class="datetimepicker-container">
                                    <div class="datetimepicker-header is-hidden">
                                        <div class="datetimepicker-selection-details">
                                            <div class="datetimepicker-selection-from is-hidden"></div>
                                            <div class="datetimepicker-selection-start is-centered">
                                                <div class="datetimepicker-selection-wrapper">
                                                    <div class="datetimepicker-selection-day">--</div>
                                                    <div class="datetimepicker-selection-date">
                                                        <div class="datetimepicker-selection-month"></div>
                                                        <div class="datetimepicker-selection-weekday is-hidden"></div>
                                                    </div>
                                                </div>
                                                <div class="datetimepicker-selection-time">
                                                    <div class="datetimepicker-selection-time-icon">
                                                        <figure class="image is-16x16">
                                                            <svg version="1.1" x="0px" y="0px" viewBox="0 0 60 60" xml:space="preserve">
                                                                <g>
                                                                    <path
                                                                        fill="currentcolor"
                                                                        d="M30,0C13.458,0,0,13.458,0,30s13.458,30,30,30s30-13.458,30-30S46.542,0,30,0z M30,58C14.561,58,2,45.439,2,30S14.561,2,30,2s28,12.561,28,28S45.439,58,30,58z"
                                                                    ></path>
                                                                    <path
                                                                        fill="currentcolor"
                                                                        d="M30,6c-0.552,0-1,0.447-1,1v23H14c-0.552,0-1,0.447-1,1s0.448,1,1,1h16c0.552,0,1-0.447,1-1V7C31,6.447,30.552,6,30,6z"
                                                                    ></path>
                                                                </g>
                                                            </svg>
                                                        </figure>
                                                    </div>
                                                    <div class="datetimepicker-selection-hour">00:00</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="timepicker">
                                        <div class="timepicker-start">
                                            <div class="timepicker-hours">
                                                <span class="timepicker-next">+</span>
                                                <div class="timepicker-input">
                                                    <input type="number" />
                                                    <div class="timepicker-input-number">00</div>
                                                </div>
                                                <span class="timepicker-previous">-</span>
                                            </div>
                                            <div class="timepicker-time-divider">:</div>
                                            <div class="timepicker-minutes">
                                                <span class="timepicker-next">+</span>
                                                <div class="timepicker-input">
                                                    <input type="number" />
                                                    <div class="timepicker-input-number">00</div>
                                                </div>
                                                <span class="timepicker-previous">-</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="datetimepicker-footer is-hidden">
                                    <button class="datetimepicker-footer-validate has-text-success button is-small is-text">{{ $translate('validate') }}</button>
                                    <button class="datetimepicker-footer-today has-text-warning button is-small is-text is-hidden">{{ $translat('today') }}</button>
                                    <button class="datetimepicker-footer-clear has-text-danger button is-small is-text">{{ $translate('clear') }}</button>
                                    <button class="datetimepicker-footer-cancel button is-small is-text">{{ $translate('cancel') }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Dropdown :options="['0-5', '5-10', '10-15', '15-20', '20-25']" :current="reportData.temperature" name="temperature" @change="UpdateReportData" />
                <Dropdown
                    :options="['All Locations', 'Rome, Italy', 'Via Panoramica, 15, Italy', 'Via Frascati Colonna, 25, Italy', 'Vocabolo Borgo 4, Italy', 'Str. del Pino, 4, Italy']"
                    :current="reportData.location"
                    name="location"
                    @change="UpdateReportData"
                />
                <Dropdown :options="['All', 'Enabled', 'Disabled']" :current="reportData.status" name="status" @change="UpdateReportData" />
            </div>
            <button class="btn btn-w-shadow btn-standart-2">
                <inline-svg :src="require(`@/assets/img/./ico-map.svg`)" />
                <span>{{ $translate('map') }}</span>
            </button>
        </div>
        <div v-if="false">
            <div class="table-wrapper">
                <table class="table">
                    <thead>
                        <tr>
                            <th>
                                <div class="table-filter flex">
                                    <label class="module__check">
                                        <input type="checkbox" name="privacy_policy" />
                                        <span class="check"></span>
                                    </label>
                                    <div class="table-filter__options flex-auto">
                                        <button class="table-filter__btn">
                                            <span> {{ $translate('date-&-time') }} </span>
                                        </button>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div class="table-filter">
                                    <div class="table-filter__options">
                                        <button class="table-filter__btn">
                                            <span> {{ $translate('location') }} </span>
                                        </button>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div class="table-filter">
                                    <div class="table-filter__options">
                                        <button class="table-filter__btn">
                                            <span> {{ $translate('temperature') }} </span>
                                        </button>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div class="table-filter">
                                    <div class="table-filter__options">
                                        <button class="table-filter__btn">
                                            <span> {{ $translate('battery') }} </span>
                                        </button>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div class="table-filter">
                                    <div class="table-filter__options">
                                        <button class="table-filter__btn">
                                            <span> {{ $translate('status') }} </span>
                                        </button>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div class="table-filter">
                                    <div class="table-filter__options">
                                        <button class="table-filter__btn">
                                            <span> {{ $translate('alert') }} </span>
                                        </button>
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <label class="module__check">
                                    <input type="checkbox" name="privacy_policy" />
                                    <span class="check"></span>
                                    <span class="text">Thermometer Name 1</span>
                                </label>
                            </td>
                            <td>
                                <div class="flex icon-text">
                                    <inline-svg :src="require(`@/assets/img/Pin-Location.svg`)" />
                                    <span>Italy, Rome</span>
                                </div>
                            </td>
                            <td>25.7°C</td>
                            <td>58%</td>
                            <td style="width: 150px">Enabled</td>
                            <td style="width: 30%">-</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="pagination fixed">
                <div class="pagination__body">
                    <div class="pagination__show">
                        <p>{{ $translate('rows-per-page') }}:</p>
                        <div class="dropdown-wrapper">
                            <button class="dropdown-btn pagination__show-btn">
                                <span>10</span>
                                <inline-svg :src="require(`@/assets/img/Arrow-down.svg`)" />
                            </button>
                            <div class="dropdown-content categories__dropdown">
                                <ul>
                                    <li>
                                        <span>10</span>
                                    </li>
                                    <li>
                                        <span>20</span>
                                    </li>
                                    <li>
                                        <span>30</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="pagination__list-wrapper">
                        <a href="#" class="pagination__btn prev disabled">
                            <inline-svg :src="require(`@/assets/img/Arrow-prev.svg`)" />
                            <span>{{ $translate('prev') }}</span>
                        </a>
                        <ul class="pagination__list">
                            <li>
                                <a href="#" class="active">1</a>
                            </li>
                            <li>
                                <a href="#">2</a>
                            </li>
                            <li>
                                <a href="#">3</a>
                            </li>
                        </ul>
                        <a href="#" class="pagination__btn next">
                            <span>{{ $translate('next') }}</span>
                            <inline-svg :src="require(`@/assets/img/Arrow-next.svg`)" />
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <div class="content__header">
                <div class="content__header-left" style="margin-top: 15px;">
                    <h3 class="heading-page-h1">{{ $translate('fields/paddocks-map') }}:</h3>
                </div>
            </div>
            <PaddocksMap :paddocks="paddocksWithDevice" mapHeightProps="50vh" />

            <div class="content__header">
                <div class="content__header-left" style="margin-top: 30px;">
                    <h3 class="heading-page-h1">{{ $translate('fields/paddocks-list') }}:</h3>
                </div>
            </div>
            <FieldsPaddockList class="field_paddock_list_inner_page" :forcePaddockList="paddocksWithDevice" />

            <div class="content__header">
                <div class="content__header-left" style="margin-top: 30px;">
                    <h3 class="heading-page-h1">{{ $translate('livestock-list') }}:</h3>
                </div>
            </div>
            <LivestockList class="field_paddock_list_inner_page" :forcePaddockList="paddocksWithDevice" />
        </div>
    </div>
</template>

<script>
import Base from "@/components/base";
import PaddocksMap from '@/views/reports/inners/map-zone-details/PaddocksMap';
import FieldsPaddockList from '@/views/FieldsPaddockList';
import LivestockList from '@/views/LivestockList';

export default {
    name: 'DeviceReportTab',
    props: ['id'],
    components: {
        ...Base,
        PaddocksMap,
        FieldsPaddockList,
        LivestockList,
    },
    data() {
        return {
            reportData: {
                temperature: "Temperature",
                location: "Location",
                status: "Status",
            },
        };
    },
    computed: {
        devices(){
            return this.$store.state.devices;
        },
        paddocks(){
			return this.$store.state.paddocks;
		},
		paddocksWithDevice(){
            return this.$store.state.paddocks.filter(item => this.devices.find(dFind => dFind.id === this.id).paddockID === item.id);
		},
    },
    methods: {
        UpdateReportData(ev) {
            const target = ev.target;
            const value = target.value;
            const name = target.name;
            this.reportData[name] = value;
        },
    }
}
</script>