<template>
<div>
    <div class="wrapper_main" v-if="!addNewRuleMode">
        <main class="content">
            <div class="content__header sub-page">
                <div class="content__header-left">
                    <router-link to="/devices" class="btn btn-w-shadow btn-sm btn-back">
                        <inline-svg :src="require(`@/assets/img/Arrow-prev.svg`)" />
                    </router-link>
                    <DeviceIcon :category="device.category" />
                    <div class="heading-wrapper">
                        <h1 class="heading-page-h1">
                            {{ device.name }}
                        </h1>
                        <ul class="heading-list" v-if="centaurDeviceInfo && apiDataName === 'Centaur'">
                            <li>
                                <inline-svg :src="require(`@/assets/img/clock-watch.svg`)" />
                                {{ centaurDeviceInfo.active ? 'Connected' : 'Disconnected' }}
                            </li>
                            <li>{{ $translate('last-data-received') }}: {{ new Date(centaurDeviceInfo.lastCommunicationDate).toLocaleString() }}</li>
                        </ul>
                        <ul class="heading-list" v-if="apiDataName === 'xFarm' && dataFromxFarm.length">
                            <li>
                                <inline-svg :src="require(`@/assets/img/clock-watch.svg`)" /> {{ 'Connected' }}
                            </li>
                            <li>{{ $translate('last-data-received') }}: {{ new Date(dataFromxFarm[dataFromxFarm.length-1].time).toLocaleString() }}</li>
                        </ul>
                    </div>
                </div>
                <div class="content__header-right">
                    <label class="toggle-check">
                        <input type="checkbox" v-model="deviceStatus" />
                        <span class="toggle-check__body-wrapper">
                            <span class="text-check">{{ $translate('enabled') }}</span>
                            <span class="text-not-check">{{ $translate('disabled') }}</span>
                            <span class="toggle-check__body">
                                <span class="toggle-check__hendler"></span>
                            </span>
                        </span>
                    </label>
                    <button class="btn btn-w-shadow btn-standart-2" :class="{ processing }" @click.prevent="refreshData">
                        <inline-svg :src="require(`@/assets/img/Arrow-Rotate.svg`)" />
                        <span>{{ $translate('refresh') }}</span>
                    </button>
                </div>
            </div>
            <div class="tabs-wrapper">
                <ul class="tabs">
                    <li class="tabs__item">
                        <a class="nav-tab" @click="switchTab('overview')" :class="isActiveTab('overview')"> {{ $translate('overview') }} </a>
                    </li>
                    <li class="tabs__item">
                        <a class="nav-tab" @click="switchTab('info')" :class="isActiveTab('info')"> {{ $translate('info') }} </a>
                    </li>
                    <li class="tabs__item" v-if="device && device.config && device.config.device_id && categories[device.category]">
                        <a class="nav-tab" @click="switchTab('measurments')" :class="isActiveTab('measurments')"> {{ $translate('measurements') }} </a>
                    </li>
                    <li class="tabs__item">
                        <a class="nav-tab" @click="switchTab('alerts')" :class="isActiveTab('alerts')"> {{ $translate('alerts') }} </a>
                    </li>
                    <li class="tabs__item">
                        <a class="nav-tab" @click="switchTab('rules')" :class="isActiveTab('rules')"> {{ $translate('rules') }} </a>
                    </li>
                    <li class="tabs__item">
                        <a class="nav-tab" @click="switchTab('schedule')" :class="isActiveTab('schedule')"> {{ $translate('schedule') }} </a>
                    </li>
                    <li class="tabs__item">
                        <a class="nav-tab" @click="switchTab('report')" :class="isActiveTab('report')"> {{ $translate('report') }} </a>
                    </li>
                </ul>
            </div>
            <div class="content__body" :key="updateKey" v-if="!processing">
                <div class="tab-content">
                    <div id="overview" class="tab-content__item" :class="isActiveTab('overview')" v-if="activeTab === 'overview'">
                        <div v-if="!mapFullscreen">
                            <div class="cards flex" v-if="device && device.config && device.config.device_id && categories[device.category]">
                                <div class="cards__col-8 cards__col-xxl-12 flex flex-col">
                                    <div class="card">
                                        <div class="card__header style-2">{{ $translate('measurements') }}</div>
                                        <div class="card__body style-2">
                                            <div class="chart flex" style="width: 100%;min-width: 100%;" v-for="(chartDataItem, cdiIndex) in chartsDataArray" :key="cdiIndex">
                                                <div class="chart__quick-info quick-info flex flex-col">
                                                    <div class="quick-info__title">
                                                        <h4 style="max-width: initial;">{{ $translate('actual') }} {{ chartDataItem.categoryTitle }}</h4>
                                                    </div>
                                                    <div class="quick-info__body flex-auto flex justify-start items-center flex-col">
                                                        <div class="actual-info flex flex-col items-center">
                                                            <!-- <img src="@/assets/img/notification-2.svg" /> -->
                                                            <DeviceIcon :category="chartDataItem.category" class="background-color-transparent" />
                                                            <span v-if="dataFromChart[cdiIndex].length">
                                                                {{ dataFromChart[cdiIndex][dataFromChart[cdiIndex].length-1].value + categories[chartDataItem.category].unit }}
                                                            </span>
                                                        </div>
                                                        <div class="actual-info flex flex-col items-center" style="font-size: 12px;">
                                                            {{ new Date(chartFirstDate).toLocaleDateString() }} - {{ new Date().toLocaleDateString() }}
                                                        </div>
                                                    </div>
                                                    <div class="quick-info__footer">
                                                        <span class="time-moment" v-if="dataFromChart[cdiIndex].length">
                                                            <vue-moments-ago prefix="" suffix="ago" :date="dataFromChart[cdiIndex][dataFromChart[cdiIndex].length-1].timestamp" lang="en" />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="chart__body flex-auto">
                                                    <div class="chart__body-wrapper" style="padding: 15px;max-height: 350px;" v-if="chartDataItem.chartData && chartDataItem.device && chartDataItem.device.category">
                                                        <!-- @setCurrent="dataFromChart[cdiIndex] = $event" -->
                                                        <MeasurmentsChart
                                                            height="338px"
                                                            :chartId="`overview-chart-${cdiIndex}`" :chartData="chartDataItem.chartData" 
                                                            :category="chartDataItem.category" :dateSort="dateSort"
                                                            :enableMax="enableMax"
                                                            :enableMin="enableMin"
                                                            @setCurrent="$set(dataFromChart, cdiIndex, $event)"
                                                        />
                                                    </div>
                                                    <div class="chart__body-actions flex">
                                                        <div class="buttons-group flex-auto">
                                                            <!-- <button v-for="chartDateItem in chartDateViews" :key="chartDateItem" @click="dateSort = chartDateItem"
                                                                class="btn btn-w-shadow btn-standart-2" :class="{'active': dateSort === chartDateItem}"
                                                            >
                                                                <span>{{ chartDateItem }}</span>
                                                            </button> -->
                                                            <div style="max-width: 150px;">
                                                                <DropdownAdvanced
                                                                    :options="chartDateViews.map(item => ({ id: item, name: item }))"
                                                                    v-model="dateSort"
                                                                    selectorClass="min-w-full"
                                                                    :toTop="true"
                                                                />
                                                            </div>
                                                            <div class="actual-info flex flex-col items-center" style="font-size: 14px;margin-left: 10px;">
                                                                {{ new Date(chartFirstDate).toLocaleDateString() }} - {{ new Date().toLocaleDateString() }}
                                                            </div>
                                                        </div>
                                                        <div class="buttons-group">
                                                            <label class="module__check around green">
                                                                <input type="checkbox" name="privacy_policy" :value="1" v-model="enableMin"/>
                                                                <span class="check"></span>
                                                                <span class="text">Min</span>
                                                            </label>
                                                            <label class="module__check around red">
                                                                <input type="checkbox" name="privacy_policy" :value="1" v-model="enableMax"/>
                                                                <span class="check"></span>
                                                                <span class="text">Max</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex" style="margin: 0 -10px;">
                                        <div class="cards__col-6 cadrs__col-xl-2 cadrs__col-md flex flex-col" v-if="alerts.length">
                                            <div class="card flex-auto">
                                                <div class="card__header style-2">{{ $translate('alerts') }}</div>
                                                <ul class="table-list">
                                                    <li class="table-list__item" v-for="alerItem in alerts" :key="alerItem.id">
                                                        <span class="table-list__td" style="text-transform: capitalize;">{{ alerItem.description.split('_').join(' ') }}</span>
                                                        <span class="table-list__td">{{ new Date(alerItem.datetime).toLocaleDateString() + ', ' + new Date(alerItem.datetime).toLocaleTimeString() }}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="cards__col-6 cadrs__col-xl-2 cadrs__col-md flex flex-col">
                                            <div class="card flex-auto">
                                                <div class="card__header flex items-center style-2">
                                                    <span class="flex-auto">{{ $translate('location') }}</span>
                                                    <button class="btn btn-transp"  @click="switchMapFullscreen(!mapFullscreen)">
                                                        <inline-svg :src="require(`@/assets/img/Full-Screen.svg`)" />
                                                    </button>
                                                </div>
                                                <div class="map" style="max-height: 388px; border-radius: 0 0 7px 7px; overflow: hidden">
                                                    <div id="device-map" style="width: 100%; height: 410px"></div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="cards__col-4 cards__col-xxl-12 flex flex-col flex-xxl-row">
                                    <!-- flex-auto -->
                                    <div class="card flex flex-col">
                                        <div class="card__header style-2">{{ $translate('battery-status') }}</div>
                                        <div class="card__body style-2 flex-auto flex items-center justify-center">
                                            <div class="card__body-sm flex flex-col items-center">
                                                <div class="actual-info flex flex-col items-center">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none" style="height: 50px;width: 50px;" v-if="apiDataName === 'xFarm' && dataFromxFarm.length">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.5 18H3.5C2.11875 18 1 17.105 1 16V8C1 6.895 2.11875 6 3.5 6H18.5C19.8813 6 21 6.895 21 8V16C21 17.105 19.8813 18 18.5 18Z" stroke="#B4C4CA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M21 9H22.014C22.169 9 22.322 9.036 22.461 9.106L22.947 9.349C23.286 9.518 23.5 9.865 23.5 10.243V13.757C23.5 14.136 23.286 14.482 22.947 14.651L22.461 14.894C22.322 14.964 22.169 15 22.014 15H21" stroke="#B4C4CA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <!-- (+dataFromxFarm[dataFromxFarm.length-1].bvLvl || 5) -->
                                                        <rect x="3" y="8" :width="(16 / 100) * (((+dataFromxFarm[dataFromxFarm.length-1].bv || 5) / 5) * 100).toFixed()" height="8" rx="1" fill="#00283A"/>
                                                    </svg>
                                                    <img src="@/assets/img/power-icon-card.svg" v-else />
                                                    <!-- (+dataFromxFarm[dataFromxFarm.length-1].bvLvl || 5) -->
                                                    <span>{{ apiDataName === 'xFarm' && dataFromxFarm.length ? (((+dataFromxFarm[dataFromxFarm.length-1].bv || 5) / 5) * 100).toFixed() : '100' }}%</span>
                                                </div>
                                                <span class="time-moment">
                                                    <vue-moments-ago prefix="" suffix="ago" :date="dataFromxFarm[dataFromxFarm.length-1].time" lang="en" v-if="apiDataName === 'xFarm' && dataFromxFarm.length" />
                                                    <template v-else>1 minute ago</template>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- flex-auto -->
                                    <div class="card flex flex-col">
                                        <div class="card__header style-2">{{ $translate('signal-strength') }}</div>
                                        <div class="card__body style-2 flex-auto flex items-center justify-center">
                                            <div class="card__body-sm flex flex-col items-center">
                                                <div class="actual-info flex flex-col items-center">
                                                    <div class="signal_strength_bars" v-if="apiDataName === 'xFarm' && dataFromxFarm.length">
                                                        <div 
                                                            class="signal_strength_bar" v-for="item in 5"
                                                            :key="item" :class="{'active': item <= dataFromxFarm[dataFromxFarm.length-1].rssiLvl }"
                                                        ></div>
                                                    </div>
                                                    <img src="@/assets/img/network-icon-card.svg" v-else />
                                                    <span>{{ dataFromxFarm.length ? dataFromxFarm[dataFromxFarm.length-1].rssi : '-70' }} dBm</span>
                                                </div>
                                                <span class="time-moment">
                                                    <vue-moments-ago prefix="" suffix="ago" :date="dataFromxFarm[dataFromxFarm.length-1].time" lang="en" v-if="apiDataName === 'xFarm' && dataFromxFarm.length" />
                                                    <template v-else>1 minute ago</template>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- flex-auto -->
                                    <div class="card">
                                        <div class="card__header flex items-center style-2">
                                            <span class="flex-auto">{{ $translate('device-info') }}</span>
                                            <button class="btn btn-transp" @click="switchTab('info')">
                                                <inline-svg :src="require(`@/assets/img/ico-settings.svg`)" />
                                            </button>
                                        </div>
                                        <ul class="table-list">
                                            <li class="table-list__item">
                                                <span class="table-list__td">{{ $translate('name') }}</span>
                                                <span class="table-list__td">{{ device.name }}</span>
                                            </li>
                                            <li class="table-list__item">
                                                <span class="table-list__td">{{ $translate('type') }}</span>
                                                <span class="table-list__td">{{ device.device_type }}</span>
                                            </li>
                                            <li class="table-list__item">
                                                <span class="table-list__td">{{ $translate('category') }}</span>
                                                <span class="table-list__td">{{ device.category }}</span>
                                            </li>
                                            <li class="table-list__item">
                                                <span class="table-list__td">ID</span>
                                                <span class="table-list__td">{{ device.id }}</span>
                                            </li>
                                            <li class="table-list__item" v-if="device.date_of_manufacture">
                                                <span class="table-list__td">{{ $translate('date-of-manufacture') }}</span>
                                                <span class="table-list__td">{{ device.date_of_manufacture }}</span>
                                            </li>
                                            <li class="table-list__item"  v-if="device.date_of_purchase">
                                                <span class="table-list__td">{{ $translate('date-of-purchase') }}</span>
                                                <span class="table-list__td">{{ device.date_of_purchase }}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="cards flex">
                                <div class="cards__col">
                                    <div class="flex flex-col">
                                        <div class="card flex-auto">
                                            <div class="card__header flex items-center style-2">
                                                <span class="flex-auto">{{ $translate('location') }}</span>
                                                <button class="btn btn-transp" @click="switchMapFullscreen(!mapFullscreen)">
                                                    <inline-svg :src="require(`@/assets/img/Full-Screen.svg`)" />
                                                </button>
                                            </div>
                                            <div class="map" style="border-radius: 0 0 7px 7px; overflow: hidden">
                                                <div id="device-map" style="width: 100%; height: 610px"></div>
                                                <!-- <PaddocksMap :paddocks="paddocksWithDevices"/> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="settings" class="tab-content__item" :class="isActiveTab('info')">
                        <div class="wizard-item footer-fixed">
                            <div class="headline headline--sm mt-15">
                                <h2 class="headline__title">{{ $translate('basic-info') }}</h2>
                            </div>
                            <div class="items-group cards">
                                <div class="cards__col-6">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('device-name') }}</label>
                                        <input type="text" class="input" v-model="device.name" />
                                    </div>
                                </div>
                                <div class="cards__col-6">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('device-category') }}</label>
                                        <DropdownAdvanced :options="deviceCategories" :current="device.category" name="category" @change="changeDeviceCategory" />
                                    </div>
                                </div>
                            </div>
                            <div class="el-form mt-15">
                                <label class="el-form__title">{{ $translate('device-description') }}</label>
                                <textarea class="textarea" v-model="device.description"></textarea>
                            </div>
                            <div class="items-group cards border-top mt-15">
                                <div class="cards__col-4">
                                    <div class="el-form mt-15">
                                        <label class="el-form__title">{{ $translate('date-of-manufacture') }}</label>
                                        <DatePicker v-model="device.date_of_manufacture" />
                                    </div>
                                </div>
                                <div class="cards__col-4">
                                    <div class="el-form mt-15">
                                        <label class="el-form__title">{{ $translate('date-of-purchase') }}</label>
                                        <DatePicker v-model="device.date_of_purchase" />
                                    </div>
                                </div>
                                <div class="cards__col-4">
                                    <div class="el-form mt-15">
                                        <label class="el-form__title">{{ $translate('warranty-period') }}</label>
                                        <DatePicker v-model="device.warranty_period" />
                                    </div>
                                </div>
                            </div>
                            <div class="items-group cards border-top mt-15" v-if="device.category">
                                <div class="cards__col-6" v-for="c in deviceConfig" :key="c.field">
                                    <div class="el-form mt-15">
                                        <label class="el-form__title">{{ c.name }}</label>
                                        <Dropdown v-if="c.options" :key="device.config[c.field]" :options="c.options" :current="device.config[c.field]" :name="c.field" @change="changeConfigField" />
                                        <input v-else type="text" class="input" v-model="device.config[c.field]" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pagination wizard-actions fixed">
                            <div class="pagination__body">
                                <button class="btn btn-w-shadow btn-standart-2" @click="cancelEditing">{{ $translate('cancel') }}</button>
                                <button class="btn btn-primary btn-standart icon-rigth" :class="{ processing }" @click="updateDeviceData">{{ $translate('save-changes') }}</button>
                            </div>
                        </div>
                    </div>
                    <div id="measurments" class="tab-content__item" :class="isActiveTab('measurments')" v-if="activeTab === 'measurments'">
                        <div class="cards flex">
                            <!-- cards__col-8 cards__col-xxl-12 -->
                            <div class="cards__col-12">
                                <div class="card" v-for="(chartDataItem, cdiIndex) in chartsDataArray" :key="cdiIndex">
                                    <div class="card__header style-2">{{ chartDataItem.categoryTitle }} {{ $translate('history') }}</div>
                                    <!-- <div class="card__header style-2">Measurments</div> -->
                                    <div class="card__body style-2">
                                        <!-- <div class="chart flex">
                                            <div class="chart__body flex-auto">
                                                <div class="chart__body-wrapper" v-if="realDeviceData && device && device.category">
                                                    <MeasurmentsChart 
                                                        :chartData="realDeviceData" :category="device.category" :dateSort="dateSort" 
                                                        height="587px" @setCurrent="dataFromChart = $event"
                                                    />
                                                </div>
                                                <div class="chart__body-actions flex">
                                                    <div class="buttons-group flex-auto">
                                                        <button v-for="chartDateItem in chartDateViews" :key="chartDateItem" @click="dateSort = chartDateItem"
                                                            class="btn btn-w-shadow btn-standart-2" :class="{'active': dateSort === chartDateItem}"
                                                        >
                                                            <span>{{ chartDateItem }}</span>
                                                        </button>
                                                    </div>
                                                    <div class="buttons-group">
                                                        <label class="module__check around green">
                                                            <input type="checkbox" name="privacy_policy" />
                                                            <span class="check"></span>
                                                            <span class="text">Min</span>
                                                        </label>
                                                        <label class="module__check around red">
                                                            <input type="checkbox" name="privacy_policy" />
                                                            <span class="check"></span>
                                                            <span class="text">Max</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                            <div class="chart flex" style="width: 100%;min-width: 100%;">
                                                <div class="chart__body flex-auto">
                                                    <div class="chart__body-wrapper" style="padding: 15px;max-height: 350px;" v-if="chartDataItem.chartData && chartDataItem.device && chartDataItem.device.category">
                                                        <!-- @setCurrent="dataFromChart[cdiIndex] = $event" -->
                                                        <MeasurmentsChart 
                                                            :chartId="`measurments-chart-${cdiIndex}`"
                                                            :chartData="chartDataItem.chartData" 
                                                            :category="chartDataItem.category" :dateSort="dateSort"
                                                            :enableMax="enableMax"
                                                            :enableMin="enableMin"
                                                            @setCurrent="$set(dataFromChart, cdiIndex, $event)"
                                                            height="338px"
                                                        />
                                                    </div>
                                                    <div class="chart__body-actions flex">
                                                        <div class="buttons-group flex-auto">
                                                            <!-- <button v-for="chartDateItem in chartDateViews" :key="chartDateItem" @click="dateSort = chartDateItem"
                                                                class="btn btn-w-shadow btn-standart-2" :class="{'active': dateSort === chartDateItem}"
                                                            >
                                                                <span>{{ chartDateItem }}</span>
                                                            </button> -->
                                                            <div style="max-width: 150px;">
                                                                <DropdownAdvanced
                                                                    :options="chartDateViews.map(item => ({ id: item, name: item }))"
                                                                    v-model="dateSort"
                                                                    selectorClass="min-w-full"
                                                                    :toTop="true"
                                                                />
                                                            </div>
                                                            <div class="actual-info flex flex-col items-center" style="font-size: 14px;">
                                                                {{ new Date(chartFirstDate).toLocaleDateString() }} - {{ new Date().toLocaleDateString() }}
                                                            </div>
                                                        </div>
                                                        <div class="buttons-group">
                                                            <label class="module__check around green">
                                                                <input type="checkbox" name="privacy_policy" :value="1" v-model="enableMin"/>
                                                                <span class="check"></span>
                                                                <span class="text">Min</span>
                                                            </label>
                                                            <label class="module__check around red">
                                                                <input type="checkbox" name="privacy_policy" :value="1" v-model="enableMax"/>
                                                                <span class="check"></span>
                                                                <span class="text">Max</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="cards__col-4 flex flex-col cards__col-xxl-12 flex-xxl-row">
                                <div class="card flex flex-col flex-auto">
                                    <div class="card__header style-2">{{ categories[device.category].title }} Status</div>
                                    <div class="card__body style-2 flex-auto flex items-center justify-center">
                                        <div class="card__body-sm flex flex-col items-center">
                                            <div class="actual-info flex flex-col items-center">
                                                <DeviceIcon :category="device.category" class="background-color-transparent" />
                                                <span v-if="dataFromChart">{{ dataFromChart[dataFromChart.length-1].value + categories[device.category].unit }}</span>
                                            </div>
                                            <span class="time-moment" v-if="dataFromChart">
                                                <vue-moments-ago prefix="" suffix="ago" :date="dataFromChart[dataFromChart.length-1].timestamp" lang="en" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="card flex flex-col flex-auto">
                                    <div class="card__header style-2">Average {{ categories[device.category].title }}</div>
                                    <div class="card__body style-2 flex-auto flex items-center justify-center">
                                        <div class="card__body-sm flex flex-col items-center">
                                            <div class="actual-info flex flex-col items-center" v-if="dataFromChart">
                                                <DeviceIcon :category="device.category" class="background-color-transparent" />
                                                <span>{{ Math.min.apply(null, dataFromChart.map(item => item.value)) + categories[device.category].unit }} – {{ Math.max.apply(null, dataFromChart.map(item => item.value)) + categories[device.category].unit }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div id="alerts" class="tab-content__item" :class="isActiveTab('alerts')" v-if="activeTab === 'alerts'">
                        <DeviceAlertsTab :alerts="alerts" :rules="device.rules.items" />
                    </div>
                    <div id="rules" class="tab-content__item" :class="isActiveTab('rules')">
                        <DeviceRulesTab :id="id" @addNewRuleOpen="addNewRuleOpen" :addNewRuleID="addNewRuleID"></DeviceRulesTab>
                    </div>
                    <div id="schedule" class="tab-content__item" :class="isActiveTab('schedule')">
                        <DeviceSheduleTab :device="device"/>
                    </div>
                    <div id="report" class="tab-content__item" :class="isActiveTab('report')" v-if="activeTab === 'report'">
                        <DeviceReportTab :id="id" />
                    </div>
                </div>
            </div>
            <div v-else class="processing" style="min-height: 300px;"></div>
        </main>

        <ConfirmAction
            v-if="deletePopupInfo.show"
            title="Delete Product?"
            message="Do you confirm deleting this product from category?"
            @close="confirmDeleteClose"
            @confirm="confirmDeleteAction"
            :processing="dProcessing"
        />
    </div>

    <RulesAdd
        v-if="addNewRuleMode"
        @close="addNewRuleClose"
        :deviceID="id"
        :isTabComponent="true"
        @ruleCreated="deviceStatus = true"
    />

</div>
</template>

<script>
import { API } from "aws-amplify";

import { updateDevice } from "@/graphql/mutations";

import { getDevice } from "@/graphql/queries";

import Base from "@/components/base";
import Tabs from "@/components/tabs";
import DeviceReportTab from "@/components/tabs/DeviceReportTab";
import Popups from "@/components/popups";
import MeasurmentsChart from "@/components/charts/MeasurmentsChart";
import RulesAdd from "./rules/RulesAdd.vue";
import VueMomentsAgo from 'vue-moments-ago';
// import PaddocksMap from '@/views/reports/inners/map-zone-details/PaddocksMap';
import { devCats, devTypesConfigs, devStatus, devicesDataCategories } from "@/config";

import { compareData } from "@/helpers";
import { mixDeleteItem } from "@/mixins";

export default {
    name: "DevicesOverview",
    props: ["id"],
    components: {
        ...Base,
        ...Tabs,
        ...Popups,
        DeviceReportTab,
        MeasurmentsChart,
        RulesAdd,
        VueMomentsAgo,
        // PaddocksMap,
    },
    mixins: [mixDeleteItem],
    data() {
        return {
            processing: false,
            deviceCategory: "Select Device Category",
            sheduleRepeat: 'Repeat weekly',
            device: {},
            backup: {},
            //delete config
            deleteQueryType: "deleteDeviceRules",
            addNewRuleMode:false,
            addNewRuleID:null,
            mapFullscreen:false,
            realDeviceData: null,
            dateSort: "Month",
            dataFromChart: [],
            chartDateViews: [ 
                "1 day",
                "2 days",
                "3 days",
                "7 days",
                "15 days",
                "Month",
                "60 days",
                "90 days",
                "Year"
            ],
            categories: devicesDataCategories,
            centaurDeviceInfo: null,
            // Centaur xFarm
            apiDataName: null,
            dataFromxFarm: [],
            chartsDataArray: [],
            updateKey: 0,
            enableMax: 0,
            enableMin: 0,
            chartFirstDate: new Date(),
        };
    },
    computed: {
        deviceCategories() {
            return devCats;
        },
        deviceConfig() {
            return devTypesConfigs[this.device.device_type];
        },
        deviceStatus: {
            get() {
                return devStatus[this.device.status];
            },
            set(bool) {
                this.device.status = devStatus[bool];
                setTimeout(() => {
                    this.updateDeviceData();
                }, 1000);
            },
        },
        activeTab: {
            get() {
                return this.$route.query.tab || "overview";
            },
            set(tab) {
                if(tab === 'overview'){
                    setTimeout(async () => {
                        await this.initMap(true);
                    }, 1000);
                }

                this.$router.push({ query: { tab } });
            },
        },
        isActiveTab() {
            return (tab) => (tab === this.activeTab ? "active" : "");
        },
        rules(){
            return this.$store.state.rules || []
        },
        sourceDataRules(){
            let fullRules=[]
            this.device.rules.items.forEach(r=>{
                let fullRule=this.rules.find(fr=>{
                    return fr.id==r.rulesID
                })
                if(fullRule){
                    fullRules.push(fullRule)
                }
            })
			return fullRules;
		},
        alerts(){
            console.log('this.$store.state.alerts:', this.$store.state.alerts);
            return this.$store.state.alerts.filter(item => item.deviceID === this.id).sort(function(a,b){
				return new Date(b.datetime) - new Date(a.datetime);
			}) || []
        },
        paddockList(){
            return this.$store.state.paddocks;
        },
    },
    watch:{
		mapFullscreen(){
            this.$nextTick(()=>{
                this.initMap(!this.mapFullscreen)
            })
		},
        dateSort(){
            this.$nextTick(()=>{
                this.refreshData();
            })
        }
	},
    methods: {
        async initMap(disableDefaultUI) {
            let location = null;
            let devicePaddok = null;

            if(this.device.location){
                location = {
                    lat: this.device.location.lat,
                    lng: this.device.location.lng
                }
            }

            if(this.device.paddockID){
                devicePaddok = this.paddockList.find(item => item.id === this.device.paddockID);
            }

            if(devicePaddok){
                if(devicePaddok.map_info.center_lat && devicePaddok.map_info.center_lng){
                    location = {
                        lat: devicePaddok.map_info.center_lat,
                        lng: devicePaddok.map_info.center_lng
                    }
                }

                if(devicePaddok.map_info.points && devicePaddok.map_info.points.length){
                    let latValue = 0;
                    let lngValue = 0;

                    for (let index = 0; index < devicePaddok.map_info.points.length; index++) {
                        latValue += devicePaddok.map_info.points[index].lat;
                        lngValue += devicePaddok.map_info.points[index].lng;                        
                    }
                    latValue = latValue / devicePaddok.map_info.points.length;
                    lngValue = lngValue / devicePaddok.map_info.points.length;

                    location = {
                        lat: latValue,
                        lng: lngValue
                    }
                }
            }

			let payload = {
				markerType: "product",
                disableDefaultUI: disableDefaultUI,
                ...location,
			}
			let map = this.$provider.gmap.createMap('device-map', payload, (res) => {
				this.setLocation(res)	
			});
		},
        switchMapFullscreen(value){
            this.mapFullscreen=value
            // setTimeout(()=>{
            //     this.initMap()
            // },1000)
        },
        async setLocation(cords) {
			let query = Object.values(cords).join();
			const { data } = await this.$provider.positionStack.getReverse(query);
            console.log(data[0]);
			if(data.length) {
				const {
					label,
					country,
					city,
					region,
                    locality
				} = data[0];
				// console.log(data[0]);
				let location={
					...cords,
					address:country+", "+locality
				}
				this.device.location=location
                this.updateDeviceData()
			}
		},
        async refreshData() {
            this.chartFirstDate = new Date();
            this.processing = true;

            this.dataFromChart = [];
            this.realDeviceData = null;
            this.centaurDeviceInfo = null;
            this.dataFromxFarm = [];
            this.chartsDataArray = [];

            const device = await API.graphql({
                query: getDevice,
                variables: {
                    id: this.id,
                },
            }).then((res) => res.data.getDevice);

            console.log('device:', device);

            if(device.config && device.config.device_id) {
                if(device.category === 'carbon-dioxide' || device.category === 'phosphine') {
                    this.realDeviceData = await this.$provider.centaurAPI.getRealDeviceData(device.config.device_id);
                    this.centaurDeviceInfo = await this.$provider.centaurAPI.getDevicesInfo(device.config.device_id);

                    if(device.category === 'carbon-dioxide'){
                        ['co2', 'rh', 'temp'].forEach(valueName => {
                            this.dataFromChart.push([]);
                            this.chartsDataArray.push({
                                categoryTitle: Object.entries(this.categories).map(itemM => itemM[1]).find(cItemF => cItemF.category === valueName).title,
                                device: device,
                                category: valueName,
                                chartData: this.realDeviceData.filter(itemF => itemF.type === valueName).map(item => {
                                    return {
                                        "timestamp": item.timestamp,
                                        "type": valueName,
                                        "value": item.value,
                                        "deviceId": item.deviceId,
                                        "fullData": null
                                    }
                                }),
                            });
                        })
                    } else {
                        this.dataFromChart.push([]);
                        this.chartsDataArray.push({
                            categoryTitle: this.categories[device.category].title,
                            device: device,
                            category: device.category,
                            chartData: this.realDeviceData.filter(itemF => itemF.type === this.categories[device.category].category).map(item => {
                                return {
                                    "timestamp": item.timestamp,
                                    "type": device.category,
                                    "value": item.value,
                                    "deviceId": item.deviceId,
                                    "fullData": null
                                }
                            }),
                        });
                    }

                    this.apiDataName = 'Centaur';

                    // console.log('this.realDeviceData:', this.realDeviceData.filter(itemF => itemF.type === this.categories[device.category].category).map(item => item.value));
                } else {
                    let periodTo = '90d';

                    switch (this.dateSort) {
                        case '1 day':
                            periodTo = '1d'
                            this.chartFirstDate.setDate( this.chartFirstDate.getDate() - 1 );
                        break;
                        case '2 days':
                            periodTo = '2d'
                            this.chartFirstDate.setDate( this.chartFirstDate.getDate() - 2 );
                        break;
                        case '3 days':
                            periodTo = '3d'
                            this.chartFirstDate.setDate( this.chartFirstDate.getDate() - 3 );
                        break;
                        case '7 days':
                            periodTo = '7d'
                            this.chartFirstDate.setDate( this.chartFirstDate.getDate() - 7 );
                        break;
                        case '15 days':
                            periodTo = '15d'
                            this.chartFirstDate.setDate( this.chartFirstDate.getDate() - 15 );
                        break;
                        case 'Month':
                            periodTo = '30d'
                            this.chartFirstDate.setDate( this.chartFirstDate.getDate() - 30 );
                        break;
                        case '60 days':
                            periodTo = '60d'
                            this.chartFirstDate.setDate( this.chartFirstDate.getDate() - 60 );
                        break;
                        case '90 days':
                            periodTo = '90d'
                            this.chartFirstDate.setDate( this.chartFirstDate.getDate() - 90 );
                        break;
                        case 'Year':
                            periodTo = '1y'
                            this.chartFirstDate.setFullYear( this.chartFirstDate.getFullYear() - 1 );
                        break;
                        default:
                            periodTo = '90d'
                            this.chartFirstDate.setDate( this.chartFirstDate.getDate() - 30 );
                            break;
                    }
                    
                    this.dataFromxFarm = await this.$provider.xFarm.getDeviceData(device.config.device_id, { 'from': new Date().toISOString(), 'periodTo': periodTo });

                    let valueVariableName = null;
					
					if(device.category === 'temperature' || device.category === 'temperature-humidity' || device.category === 'weather-station-xsense'){
						valueVariableName = 'at';
					}
					
					if(device.category === 'leaf-moisture'){
						valueVariableName = 'lw';
					}
					
					if(device.category === 'soil-moisture'){
						valueVariableName = 'sm1';
					}

                    this.realDeviceData = this.dataFromxFarm.map(item => {
                        return {
                            "timestamp": item.time,
                            "type": 'temp',
                            "value": item[valueVariableName],
                            "deviceId": item.did,
                            "fullData": item
                        }
                    });

                    this.apiDataName = 'xFarm';

                    this.chartsDataArray.push({
                        categoryTitle: this.categories[device.category].title,
                        device: device,
                        category: device.category === 'weather-station-xsense' ? 'temperature' : device.category,
                        chartData: this.dataFromxFarm.map(item => {
                            return {
                                "timestamp": item.time,
                                "type": 'temp',
                                "value": item[valueVariableName],
                                "deviceId": item.did,
                                "fullData": item
                            }
                        }),
                    });

                    this.dataFromChart.push([]);

                    if(device.category === 'temperature-humidity' || device.category === 'weather-station-xsense'){
                        let charts = [];

                        if(device.category === 'temperature-humidity'){
                            charts = [
                                ['Dew point temp', 'temperature', 'temp', 'atd'],
                                ['Air humidity', 'ah', 'ah', 'ah'],
                            ]
                        }

                        if(device.category === 'weather-station-xsense'){
                            charts = [
                                ['Dew point temp', 'temperature', 'temp', 'atd'],
                                ['Air humidity', 'ah', 'ah', 'ah'],
                                ['Rain Meter', 'rain-meter', 'mm', 'rm'],
                                ['Average wind speed', 'average-wind-speed', 'aws', 'aws'],
                                ['Max wind speed', 'max-wind-speed', 'mws', 'mws'],
                                ['Solar Radiation', 'solar-radiation', 'W/m2', 'sr'],
                            ]
                        }

                        for (let index = 0; index < charts.length; index++) {
                            this.dataFromChart.push([]);
                        }

                        for (let index = 0; index < charts.length; index++) {
                            this.chartsDataArray.push({
                                categoryTitle: charts[index][0],
                                device: device,
                                category: charts[index][1],
                                chartData: this.dataFromxFarm.map(item => {
                                    return {
                                        "timestamp": item.time,
                                        "type": charts[index][2],
                                        "value": item[charts[index][3]],
                                        "deviceId": item.did,
                                        "fullData": item
                                    }
                                }),
                            });
                        }
                    }
                }
            }

            this.device = { ...device };
            this.backup = JSON.parse(JSON.stringify(device));

            this.processing = false;
            this.updateKey++;
            await this.$nextTick(async ()=>{
                if(this.activeTab === 'overview'){
                    await this.initMap(true);
                }
            })
        },
        changeConfigField(ev) {
            const { name, value } = ev.target;
            this.device.config[name] = value;
        },
        changeDeviceCategory(ev) {
            const { value } = ev.target;
            this.device.category = value.id;
        },

        switchTab(tab) {
            this.activeTab = tab;
        },
        updateDeviceCategory(ev) {
            const target = ev.target;
            const value = target.value;
            const name = target.name;
            this[name] = value;
        },

        updateDeviceField(ev) {
            const { name, value } = ev.target;
            this.device[name] = value;
        },

        restoreFromBackup() {
            this.device = { ...this.backup };
        },
        
        cancelEditing(){
            this.$router.push('/devices');
        },

        async updateDeviceData() {
            this.processing = true;
            const update = compareData(this.device, this.backup, ["location", "config"]);

            console.log('update:', update);

            if (!Object.entries(update).length) {
                this.processing = false;
                console.log("no updates");
                return;
            }

            const input = {
                ...update,
                id: this.id,
            };
            const device = await API.graphql({
                query: updateDevice,
                variables: {
                    input,
                },
            }).then((res) => res.data.updateDevice);

            this.device = { ...device };
            this.backup = JSON.parse(JSON.stringify(device));

            this.$store.commit("UPDATE_ITEM", { field: "devices", item: device });
            this.processing = false;
            await this.$nextTick(async ()=>{
                if(this.activeTab === 'overview'){
                    await this.initMap(true);
                }
                this.$router.push({ path: `/devices/` });
            })
        },
        addNewRuleOpen(){
            this.addNewRuleMode=true
            this.switchTab("info")
        },
        addNewRuleClose(){
            this.addNewRuleMode=false
            this.switchTab("rules")
        },
        // addNewRuleSubmit(rule){
        //     if(rule.id){
        //         console.log("submit overwiew");
        //         this.addNewRuleID=rule.id   
        //     }
        //     this.addNewRuleClose()
        // }
    },
    async created() {
        await this.refreshData();

        if(this.activeTab === 'overview'){
            await this.initMap(true);
        }
    },
};
</script>
